import React from "react"

function ProjectCard({ live, title, tagline, description, github }) {

  function expandCard(id) { // Expand card when button pressed and adjust button text to match
    var description = document.getElementById(id)
    description.classList.toggle("expandedCard")

    var button = document.getElementById(`${id}-button`)
    if (button.innerText === "Show more") {
      button.innerText = "Show less"
    }
    else {
      button.innerText = "Show more"
    }
  }

  return(
    <div className="project-card" tabIndex={0}>

        <div className="project-overview">
          <header>
            <h2>{title}&#58;</h2>
            <span>&nbsp;&nbsp;{tagline}</span>
          </header>

          <a target="_blank" rel="noopener noreferrer" href={live}>
            <img src={ require(`../img/project-screenshots/${title}.jpg`) } alt={"Screenshot of " + title}></img>
          </a>

          <div id={title} className="project-description">
            <p>{description}</p>
            { github && <a href={github} target="new"><strong>Github repository</strong></a> }
          </div>

          <div id={`${title}-button`} className="expand-button" onClick={() => expandCard(title)}>
            Show more
          </div>
        </div>


      <div className="project-description">
        <p>{description}</p>
        { github && <a href={github} target="new"><strong>Github repository</strong></a> }
      </div>
    </div>
  )
}

export default ProjectCard

import React from "react"

import "./about.scss"

function About() {
  return(
    <div id="about-page">
      
      <h2>
        I am a Full Stack Web Developer with 2+ years of professional experience.
      </h2>
      <p>
        
        Since 2021 I've worked at a 
        small WordPress/WooCommerce agency creating and supporting ecommerce sites for a range of companies 
        across the UK and beyond. Focusing on ensuring maximum ease of use, especially in regards
        to accesibility and checkout flow, whilst staying visually appealing, performant and secure.
      </p>

      <p>
        Outside of my professional experience,
        as a hobbyist I've been particularly interested in creating single page web applications and tools as 
        well as exploring public APIs and data scraping projects. But I'm always looking to expand my skillset and learn new 
        technologies whilst keeping up to date with and finding new features in the ones 
        I already use daily. 
      </p>

        <div className="techtools">
          <div className="aligned-ul">
            <h2>My Technologies</h2>
            <ul>
              <li>JavaScript</li>
              <li>HTML5</li>
              <li>SCSS</li>
              <li>PHP</li>
              <li>React</li>
              <li>Node.js</li>
              <li>Express.js</li>
            </ul>
          </div>
          <div className="aligned-ul">
            <h2>My Tools</h2>
            <ul>
              <li>Git</li>
              <li>NPM</li>
              <li>Jest (unit testing)</li>
              <li>CLI basics</li>
              <li>Stack Overflow...</li>
            </ul>
          </div>
        </div>

        <p>And when I'm not coding I also enjoy bouldering, running, wildcamping and photography, just to name a few things.</p>

    </div>
  )
}

export default About

import React from 'react';

function GalleryPost({ index, title, content, imagePreviewURL, imageURL, imageAlt, imageWidth, imageHeight }) {
   
    let height;
    let width;
    const baseImageDimension = 500;
    let style = {};

    if ( imageHeight > imageWidth ) {
        height = baseImageDimension * ( imageHeight > imageWidth );
        width = baseImageDimension;
        style.maxWidth = '66.66%';
    } else {
        height = baseImageDimension;
        width =  baseImageDimension * ( imageWidth / imageHeight );
    }

    return(
       

        <article className="gallery-card">

            <a
                href={imageURL} 
                data-src={imageURL}
            >
            
                <img 
                    src={imagePreviewURL} 
                    alt={imageAlt}
                    loading={index<2 ? 'eager' : 'lazy'}
                    width={width}
                    height={height}
                    style={style}
                />
            
            </a>
            
            
            <div 
                className="gallery-card__details"
                style={style}
            >
                {content}
            </div>

        </article>

       
    )
}

export default GalleryPost;
import React from "react"

import Banner from "./components/Banner"

import "./home.scss"

function Home() {
  return(
    <div className="home-splashscreen">

      <Banner
        textContent={"Hi, I'm David"}
        className={"header"}
      />

        <p className="tagline">
          I'm an experienced web developer from Sheffield, England looking for my next role.
        </p>
        <ul className="technologies">
          <li>JavaScript</li>  
          <li>HTML5</li>
          <li>CSS3</li>
          <li>PHP</li>
          <li>React</li>
          <li>Node.js</li>
          <li>Express.js</li>
        </ul>
    </div>
  )
}

export default Home

import React from "react"

import "./contact.scss"

function Contact() {
  return(
    <div className="contact">
      <p>Want to get in touch?</p>
      <a href="mailto:david@dmills.uk" className="email">david@dmills.uk</a>
      <p>I will happily provide a full CV on request, just send an email my way and let's have a chat!</p>
    </div>
  )
}

export default Contact

import React,  { useState, useEffect, useCallback } from "react"
import './theme-switch.scss'

function ThemeSwitch() {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    const [ theme , setTheme ] = useState( prefersDarkScheme ? 'dark' : 'light' );

    /* Handles the class switching for the styling of the dark/light buttons */
    const toggleButtonClasses = useCallback(() => {
        document.querySelectorAll('.mode').forEach( el => {
            el.classList.remove('active');
        })
        document.querySelector(`.mode.${theme}`).classList.add('active');
    },[theme]);

    /* Updates the theme set on the root html if a button is clicked and it is different to current */
    useEffect(() => {
        document.querySelector('html').setAttribute('data-theme', theme);
        toggleButtonClasses();
    },[theme, toggleButtonClasses]);

    
    return(
        <div className="themeswitch">
            <button
                className="mode light"
                onClick={ () => setTheme('light') }
                >
                <span className="material-symbols-outlined">
                    light_mode
                </span>
            </button>
            <button
                className="mode dark"
                onClick={ () => setTheme('dark') }
                >
                 <span className="material-symbols-outlined">
                    dark_mode
                </span>
            </button>
        </div>
    )
}

export default ThemeSwitch
import React from "react"
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom"

import Home from "./Home"
import Portfolio from "./Portfolio"
import Contact from "./Contact"
import About from "./About"
import Gallery from "./components/gallery/Gallery"

import ThemeSwitch from "./components/ThemeSwitch"

import "./app.scss"

function App() {

  return(

    <BrowserRouter>
      <div id="app-container">

        <nav>
          <NavLink
            to="/"
            exact="true"
            className={ (props)=>props.isActive ? "nav-item activeLink" : "nav-item" }>
            <span>Home</span>
          </NavLink>

          <NavLink
            to="/about"
            exact="true"
            className={ (props)=>props.isActive ? "nav-item activeLink" : "nav-item" }>
            <span>About</span>
          </NavLink>

          <NavLink
           to="/portfolio"
           exact="true"
           className={ (props)=>props.isActive ? "nav-item activeLink" : "nav-item" }>
           <span>Portfolio</span>
          </NavLink>

          <NavLink
            to="/gallery"
            exact="true"
            className={ (props)=>props.isActive ? "nav-item activeLink" : "nav-item" }>
            <span>Gallery</span>
          </NavLink>

          <NavLink
            to="/contact"
            exact="true"
            className={ (props)=>props.isActive ? "nav-item activeLink" : "nav-item" }>
            <span>Contact</span>
          </NavLink>

        </nav>

        <Routes>
          <Route exact="true" path="/" element={ <Home/> } />
          <Route exact="true" path="/portfolio" element={ <Portfolio/> } />
          <Route exact="true" path="/contact" element={ <Contact/> } />
          <Route exact="true" path="/about" element={ <About/> } />
          <Route exact="true" path="/gallery" element={ <Gallery/> } />
        </Routes>

        <ThemeSwitch></ThemeSwitch>

      </div>
    </BrowserRouter>

  )
}

export default App

import React from "react"
import "./projects-gallery.scss"

import ProjectCard from "./ProjectCard"
import projects from "../data/projects"

function ProjectsGallery() {
  return(
    <div className="projects-gallery">
      {
        projects.map(project => {
          return (
            <ProjectCard
              key={project.id}
              title={project.title}
              tagline={project.tagline}
              description={project.description}
              github={project.github}
              live={project.live}
            />
          )
        })
      }
    </div>
  )
}

export default ProjectsGallery

import React, { useState, useEffect } from 'react';

import GalleryPost from './GalleryPost';

import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';

import './gallery.scss';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

function Gallery() {

    const [ photos, setPhotos ] = useState([]);

    useEffect(() => {
        const cmsURL = 'http://wp.dmills.uk/' 
        const endpoint = 'wp-json/wp/v2/photo?per_page=100';

        fetch( cmsURL + endpoint, {
            method: 'GET'
        })
        .then(res => res.json() )
        .then(posts => {
            console.log( posts )
            setPhotos( posts)
        })
        .catch( err => {
            console.errror( err)
        })
    }, []);

    return(
        <div className="gallery-container">

            <div className="page-description">
                <p>
                    Here's a gallery of some of my photography, mostly just added to test out using headless WordPress, but feel free to browse anyway!
                </p>
            </div>

            <LightGallery
                speed={300}
                mode={'lg-slide'}
                download={false}
                plugins={[lgZoom]}
                selector={'.gallery-card a'}
                counter={false}
                title={false}
                getCaptionFromTitleOrAlt={false}
                mobileSettings={{controls: false}}
            >
            {
                photos.map( (post, index) => {
                    return(
                        
                            <GalleryPost
                                index={index}
                                key={post.id}
                                imagePreviewURL={post.acf.image.sizes.medium_large}
                                imageURL={post.acf.image.url}
                                imageAlt={post.acf.image.alt}
                                imageWidth={post.acf.image.width}
                                imageHeight={post.acf.image.height}
                            />
                        
                    )
                })
            }
            </LightGallery>

        </div>
    )
}

export default Gallery;